export const DEFAULT_COLUMN_SETTINGS = [
  {
    value: 'name',
    text: 'leads.table.labels.name',
    visible: true
  },
  {
    value: 'conversionCountByType.VALUATION',
    text: 'leads.table.labels.valuation',
    visible: true,
    hideDisplay: true
  },
  {
    value: 'conversionCountByType.RETURN_CALL',
    text: 'leads.table.labels.return-call',
    visible: true,
    hideDisplay: true
  },
  {
    value: 'conversionCountByType.LIFE_ANNUITY',
    text: 'leads.table.labels.life-annuity',
    visible: true,
    hideDisplay: true
  },
  {
    value: 'conversionCountByType.PARTIAL_SALE',
    text: 'leads.table.labels.partial-sale',
    visible: true,
    hideDisplay: true
  },
  {
    value: 'conversions.download.postalShipping',
    text: 'leads.table.labels.postal-shipping',
    visible: true,
    hideDisplay: true
  },
  {
    value: 'conversionCountByType.PLOT_VALUATION',
    text: 'leads.table.labels.plot-valuation',
    visible: true,
    hideDisplay: true
  },
  {
    value: 'conversionCountByType.PROPERTY_REQUEST',
    text: 'leads.table.labels.property-request',
    visible: true,
    hideDisplay: true
  },
  {
    value: 'conversions.preConversion.type.QUIZ',
    text: 'leads.table.labels.quiz',
    visible: true,
    hideDisplay: true
  },
  {
    value: 'conversionCountByType.DOWNLOAD',
    text: 'leads.table.labels.download',
    visible: true,
    hideDisplay: true
  },
  {
    value: 'currentStatus',
    text: 'leads.table.labels.status',
    visible: true
  },
  {
    value: 'createdAt',
    text: 'leads.table.labels.created',
    visible: false
  },
  {
    value: 'conversions',
    text: 'leads.table.labels.actions',
    visible: true
  },
  {
    value: 'lastConversionAt',
    text: 'leads.table.labels.last-action',
    visible: true
  },
  {
    value: 'city',
    text: 'leads.table.labels.city',
    visible: false
  },
  {
    value: 'zip',
    text: 'leads.table.labels.zip',
    visible: false
  },
  {
    value: 'assignedUser',
    text: 'leads.table.labels.employee',
    visible: true
  },
  {
    value: 'confirmedAt',
    text: 'leads.table.labels.confirmed',
    visible: true
  },
  {
    value: 'email',
    text: 'leads.table.labels.email',
    visible: false
  },
  {
    value: 'exportedAt',
    text: 'leads.table.labels.exported',
    visible: false
  }
]

export const DEFAULT_HEADERS = [
  {
    text: '',
    value: 'confirmedAt',
    sortable: false,
    width: '20px',
    filterable: false
  },
  {
    text: 'leads.table.labels.email',
    value: 'email',
    sortable: false
  },
  {
    text: 'leads.table.labels.name',
    value: 'name',
    sortable: false
  },
  {
    text: 'leads.table.labels.created',
    value: 'createdAt',
    filterable: false
  },
  {
    value: 'conversions',
    text: 'leads.table.labels.actions',
    visible: true,
    filterable: false,
    sortable: false
  },
  {
    text: 'leads.table.labels.last-action',
    value: 'lastConversionAt',
    filterable: false
  },
  {
    value: 'city',
    text: 'leads.table.labels.city',
    filterable: false
  },
  {
    value: 'zip',
    text: 'leads.table.labels.zip',
    filterable: false
  },
  {
    text: 'leads.table.labels.status',
    value: 'currentStatus',
    sortable: false
  },
  {
    text: 'leads.table.labels.exported',
    value: 'exportedAt',
    filterable: false
  },
  {
    text: 'leads.table.labels.employee',
    value: 'assignedUser',
    sortable: true
  },
  {
    value: 'conversionCountByType.VALUATION',
    sortable: false
  },
  {
    value: 'conversionCountByType.RETURN_CALL',
    sortable: false
  },
  {
    value: 'conversionCountByType.LIFE_ANNUITY',
    sortable: false
  },
  {
    value: 'conversionCountByType.PARTIAL_SALE',
    sortable: false
  },
  {
    value: 'conversionCountByType.PROPERTY_REQUEST',
    sortable: false
  },
  {
    value: 'conversionCountByType.PLOT_VALUATION',
    sortable: false
  },
  {
    value: 'conversions.download.postalShipping',
    sortable: false
  },
  {
    value: 'actions',
    align: 'end',
    width: 1,
    sortable: false
  }
]
